angular.module('MyHippoProducer.Services').factory('FlagshipService', function ($http, UserService) {
    const {
        FLAGSHIP_API_KEY,
        FLAGSHIP_ENV_ID,
        FLAGSHIP_CAMPAIGN_ID
    } = window.appConfig;
    const getCampaignData = (
        { campaignID,
            producerId,
            organizationId,
            parentOrganizationId,
            rootOrganizationId
        }) => {

        const fetchedProducerId = producerId || UserService.getId();
        const fetchedOrgId = organizationId ||
            (UserService.getOrgId() ? UserService.getOrgId().toString() : '');
        const fetchedParentOrgId = parentOrganizationId ||
            (UserService.getParentOrgId() ? UserService.getParentOrgId().toString() : '');
        const fetchedRootOrgId = rootOrganizationId ||
            (UserService.getRootOrgId() ? UserService.getRootOrgId().toString() : '');
        return $http({
            url: `https://decision.flagship.io/v2/${FLAGSHIP_ENV_ID}/campaigns/${campaignID ? campaignID: FLAGSHIP_CAMPAIGN_ID}`, // default campaign to react
            method: 'POST',
            params: { mode: 'simple' },
            data: {
                visitor_id: fetchedProducerId,
                context: {
                    'organization_id': fetchedOrgId || '',
                    'parent_organization_id': fetchedParentOrgId || '',
                    'root_organization_id': fetchedRootOrgId || '',
                },
            },
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': FLAGSHIP_API_KEY,
                'Cache-Control': undefined
            },
            withCredentials: false
        });
    };
    return { getCampaignData };
});
